.main__FormField {
  label {
    font-weight: bold;
    font-size: 18px;
  }
}

p-inputnumber,
.p-inputnumber {
  display: inline !important;
}

@media (max-height: 900px) and (min-width: 1090px) {
  .menu-separator {
    margin: 0.5rem 0 !important;
  }
  .layout-root-menuitem > a {
    padding: 0.2rem 0 !important;
    i {
      font-size: 1.1rem !important;
    }
    span {
      font-size: 0.8rem !important;
    }
  }
}

.p-column-filter-buttonbar {
  button {
    span {
      display: none !important;
    }
    &:first-child {
      &:after {
        content: 'Odustani' !important;
      }
    }
    &:after {
      content: 'Pretraži' !important;
    }
  }
}

.loading-indicator {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loading-indicator:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.53);
}

.main__Title {
  font-weight: 900;
}

.checkboxField__Wrapper {
  display: flex;
  input {
    width: 20px !important;
    margin-right: 10px;
  }
}
.checkboxField__Label {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.missingSchools__Dialog {
  @media (min-width: 768px) {
    width: 600px;
  }
  @media (max-width: 768px) {
    width: 400px;
  }
  @media (max-width: 600px) {
    width: 330px;
  }
  .p-dialog-header-icon {
    @media (max-width: 600px) {
      margin-top: -110px;
    }
    margin-top: -100px;
    margin-right: -50px !important;
    background: #56717d !important;
    color: #ffff !important;
  }
  .missingSchool__ListWrapper {
    margin-bottom: 40px;
  }
}

.missingSchool__List {
  border-top: 1px solid #dcdcdc;
  list-style: none;

  .missingSchool__ListItem {
    padding: 16px 0;
    border-bottom: 1px solid #dcdcdc;
  }
}
