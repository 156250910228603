$primaryColor: #a6d5fa;
$primaryLightColor: scale-color($primaryColor, $lightness: 60%) !default;
$primaryDarkColor: scale-color($primaryColor, $lightness: -10%) !default;
$primaryDarkerColor: scale-color($primaryColor, $lightness: -20%) !default;
$primaryTextColor: #ffffff;
$menuitemTextColor: #fff !important;

$highlightBg: #e3f2fd;
$highlightTextColor: #495057;
$sidebarBgColor: #d33;
$buttonBg: #343a40 !default;

@import "./../../sass/theme/theme_light";

.field {
  input {
    width: 100%;
  }
}

.p-error {
  padding-left: 2px;
  padding-top: 5px;
}

.p-card-header {
  padding: 20px;
}

.members-details {
  .p-card {
    max-width: 1200px;
    margin: auto;
  }
}
.content-box {
  position: relative;
  margin: 1rem 0px;

  &:after {
    content: "";
    position: absolute;
    bottom: 0px;
    height: 1px;
    background-color: #c7c7c7;
    width: 90%;
  }
}

.textarea-colum {
  max-width: 350px;
  word-wrap: break-word;
}

.list-column {
  max-width: 350px;
  .p-tag {
    margin: 0.2rem;
    background-color: #f5f9ff;

    span {
      color: #000;
    }
  }
}

.ng-select.ng-select-single .ng-select-container {
  height: 40px;
}

.widget-overview-box.widget-overview-box-1 {
  border-left-color: $sidebarBgColor!important;
}

.isError .ng-select {
  border: 1px solid #f44336;
}

.widget-overview-box.widget-overview-box-1 .overview-title{
  color: $sidebarBgColor!important;;
}

.p-toolbar-group-left{
  display: block!important;
  @media only screen and (max-width: 400px) {
   button{
     margin-top: .4rem;
     margin-bottom: .4rem;
   }
  }
}
